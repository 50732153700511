import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Heading from "../Heading"

const query = graphql`
  {
    allContentfulAboutMe {
      nodes {
        designation
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`

const AboutCompany = () => {
  const data = useStaticQuery(query)
  const partialAbout = data?.allContentfulAboutMe?.nodes?.[0]

  return (
    <section className="bg-[#0A0A0A] text-white">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div>
          <StaticImage
            src="../../assets/images/about-us-section-background.jpg"
            className="w-full h-full"
            quality="100"
            placeholder="blurred"
            alt="about image"
          />
        </div>
        <div className="p-10 md:p-12">
          <Heading
            preHeading="about company"
            heading={partialAbout?.designation}
          />
          <div
            className="mt-8 about-company"
            dangerouslySetInnerHTML={{
              __html: partialAbout?.description?.childMarkdownRemark?.html,
            }}
          />
        </div>
      </div>
    </section>
  )
}

export default AboutCompany
