import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Heading from "../Heading"

const FlipCard = ({ title, image, iconClassName, description }) => (
  <div className="relative transform preserve-3d transition duration-500 ease-linear group hover:rotate-y-180 select-none">
    <div className="absolute top-0 left-0 backface-invisible w-full h-full">
      <div className="absolute top-0 left-0 w-full h-full transition duration-700 group-hover:opacity-0">
        {image}
      </div>
      <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50"></div>
      <div className="relative w-full h-full flex flex-col justify-center items-center">
        <i className={`${iconClassName} before:text-[50px]`}></i>
        <h3 className="mt-4 text-2xl uppercase">{title}</h3>
      </div>
    </div>
    <div className="relative backface-invisible w-full h-full rotate-y-180 flex items-center">
      <div className="absolute top-0 left-0 w-full h-full">
        <StaticImage
          src="../../assets/images/about-us-flip-card-overlay.jpg"
          className="w-full h-full"
          quality="100"
          placeholder="blurred"
          alt="philosophy 3"
        />
      </div>
      <p className="relative text-xl px-10 py-20 text-center flex justify-center items-center">
        {description}
      </p>
    </div>
  </div>
)

const Philosophy = () => {
  return (
    <section className="relative">
      <div className="absolute top-0 left-0 w-full h-full bg-[#1A1A1A]" />
      <div className="relative container mx-auto max-w-7xl px-6 py-24">
        <Heading
          preHeading="company strategy"
          heading="Our Philosophy"
          center
        />
        <div className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-8">
          <FlipCard
            title="our missions"
            iconClassName="flaticon-brickwall"
            image={
              <StaticImage
                src="../../assets/images/about-us-philosophy-1.jpg"
                className="w-full h-full"
                quality="100"
                placeholder="blurred"
                alt="philosophy 1"
              />
            }
            description="Cùng với nhu cầu và nguyện vọng, cá tính của khách hàng, kết hợp với sự sáng tạo trong thiết kế, chúng tôi sẽ đưa ra giải pháp phù hợp nhất với từng khách hàng. Chúng tôi luôn đồng và hỗ trợ hành với khách hàng trong suốt quá trình xây dựng."
          />
          <FlipCard
            title="our vision"
            iconClassName="flaticon-color-palette"
            image={
              <StaticImage
                src="../../assets/images/about-us-philosophy-2.jpg"
                className="w-full h-full"
                quality="100"
                placeholder="blurred"
                alt="philosophy 2"
              />
            }
            description="Sáng tạo, bền vững, tôn trọng giá trị thiết kế."
          />
          <FlipCard
            title="our values"
            iconClassName="flaticon-cube"
            image={
              <StaticImage
                src="../../assets/images/about-us-philosophy-3.jpg"
                className="w-full h-full"
                quality="100"
                placeholder="blurred"
                alt="philosophy 3"
              />
            }
            description="Trung thực, tận tâm, trách nhiệm, sáng tạo, đổi mới, tôn trọng."
          />
        </div>
      </div>
    </section>
  )
}

export default Philosophy
