import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import SmallBanner from "../components/SmallBanner"
import AboutCompany from "../components/AboutUsPage/AboutCompany"
import Philosophy from "../components/AboutUsPage/Philosophy"
import Team from "../components/AboutUsPage/Team"

const AboutUsPage = () => {
  return (
    <>
      <GatsbySeo title="About Us" titleTemplate="%s | BESHAUS" />
      <SmallBanner
        title="About Us"
        gatsbyImage={
          <StaticImage
            src="../assets/images/about-us-banner-background.jpg"
            className="w-full h-full"
            quality="100"
            placeholder="blurred"
            alt="portfolio banner background"
          />
        }
      />
      <AboutCompany />
      <Philosophy />
      <Team />
    </>
  )
}

export default AboutUsPage
