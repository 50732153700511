import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import Heading from "../Heading"

const Team = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulTeamMember(sort: { fields: displayOrder, order: ASC }) {
        nodes {
          id
          name
          role
          image {
            gatsbyImageData(
              placeholder: BLURRED
              layout: FULL_WIDTH
              aspectRatio: 1
              quality: 100
            )
          }
        }
      }
    }
  `)
  const teamMembers = data?.allContentfulTeamMember?.nodes

  return (
    <section className="bg-[#0A0A0A]">
      <div className="py-20">
        <div className="px-6">
          <Heading
            preHeading="our professionals"
            heading="Meet Our Skilled Team"
            center
          />
        </div>
        <div className="mt-20">
          <Swiper
            modules={[Pagination]}
            slidesPerView={1}
            grabCursor
            breakpoints={{
              640: { slidesPerView: 2 },
              768: { slidesPerView: 3 },
              1024: { slidesPerView: 5 },
            }}
            pagination={{
              clickable: true,
              el: ".swiper-custom-pagination",
              bulletClass: "swiper-custom-pagination-bullet",
              bulletActiveClass: "swiper-custom-pagination-bullet-active",
            }}
          >
            {teamMembers.map(({ id, name, role, image }) => (
              <SwiperSlide
                key={id}
                className="h-auto flex self-stretch relative group"
              >
                <div className="w-full h-full overflow-hidden min-h-[350px]">
                  <GatsbyImage
                    image={getImage(image)}
                    className="w-full h-full transition-transform duration-700 ease-in-out transform group-hover:scale-110 filter group-hover:grayscale"
                    alt={name}
                  />
                </div>
                <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center transition-opacity duration-500 ease-in-out opacity-0 group-hover:opacity-100 text-white text-center">
                  <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-70"></div>
                  <div className="relative p-10 uppercase select-none">
                    <h3 className="text-3xl">{name}</h3>
                    <h4 className="mt-4 text-base font-josefin-sans">
                      [ {role} ]
                    </h4>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="mt-16">
          <div className="swiper-custom-pagination"></div>
        </div>
      </div>
    </section>
  )
}

export default Team
